import React, { useState, useEffect } from 'react';
import API from '../../../../../libs/api-lib';
import { Button, Form } from 'semantic-ui-react';
import { CHECKBOX, FORM_TOGGLE } from '../../../../../components/cmp_form/cmp_form';

import './crd_features.css';
import Processing from '../../../../../components/cmp_processing';

export default function CRD_FEATURES({ organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_features, set_features ] = useState({});
    const [ var_working_features, set_working_features ] = useState({});
    const [ var_disabled_features, set_disabled_features ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const feature_list = [
        { 
            feature: 'networks', 
            name: 'Affiliate network', 
            info: 'Establish a network of connected organizations to enable sharing of assignments, resources, and related items.',
            sub_features: [
                {
                    feature: 'network_invite',
                    name: 'Externally visible',
                    info: 'Make this organization visible to others, allowing them to invite it to join their affiliate network.'
                }
            ]
        },
        { 
            feature: 'assignments', 
            name: 'Assignments', 
            info: 'Assignments allow for the grouping of individuals, requirements, documents, and other related items.',
            sub_features: [
                {
                    feature: 'dailycheck',
                    name: 'Daily Check',
                    info: 'Present a daily questionnaire that users are required to complete.'
                },
                { 
                    feature: 'r2w', 
                    name: 'Onsite', 
                    info: 'Enable individuals to declare their physical presence on a work site.' 
                },
                { 
                    feature: 'qraccess', 
                    name: 'QR Access', 
                    info: 'Enable individuals to join an assignment by scanning a QR code.' 
                },
            ]
        },
        { 
            feature: 'credentials', 
            name: 'Credentials', 
            info: "Allow Admins to view an individual's credentials from either the worker's profile or an assignment.",
            sub_features: [
                { 
                    feature: 'manage_credentials', 
                    name: 'Manage credentials', 
                    info: "Allow Admins to activate or deactivate an individual's credentials."
                },
            ]
        },
        { feature: 'demoaccount', name: 'Demo organization', info: 'This is a demo organization.' },
        { feature: 'documents', name: 'Documents', info: 'Upload documents to be acknowledged by individuals.' },
        { feature: 'experimental', name: 'Experimental', info: 'This organization has experimental features.', read_only: true },
        { feature: 'guest', name: 'Guest', info: "Enable the organization to allow individuals to join as 'Guests.'" },
        { feature: 'identity_verification', name: 'Identity verification', info: 'The organization can perform identity verifications.', read_only: true },
        { 
            feature: 'issuer', 
            name: 'Issuer', 
            info: 'This organization issues credentials',
            sub_features: [
                { 
                    feature: 'credentials_ext', 
                    name: 'External visibility', 
                    info: 'The organization and its credentials are accessible to individuals outside of this organization.' 
                },
                { 
                    feature: 'verifiable_credentials', 
                    name: 'Verifiable Credentials', 
                    info: 'The organization issues credentials as verifiable credentials.' 
                },
                { 
                    feature: 'proof_type', 
                    name: 'Verifiable credentials - Proof type', 
                    info: 'Enable the selection of the data proof type when creating a verifiable credential.' 
                },
            ]
        },
        { feature: 'mfa', name: 'MFA', info: 'Enable multifactor authentication for the organization.' },
        { feature: 'reports', name: 'Reports', info: 'Enable reports for the organization.' },
        { feature: 'requirements', name: 'Requirements', info: 'Monitor compliance of individuals with credential requirements and packages.' },
        { feature: 'vc-wallet', name: 'VC Wallet', info: 'Enable support for verifiable credentials in the cloud wallet.', read_only: true },
        { feature: 'verification_templates', name: 'Verification Templates', info: 'The organization can create verification templates for VC verification.', read_only: true },
    ];



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        set_mode('VIEW');
        try {
            let record = await API_get_organization_features();
            set_features({id: organization_id, ...record});
        } catch (error) {
            console.error(error);
        }
    }

    async function save_record() {
        set_processing(true);
        try {
            await API_put_organization_features();
            set_features({...var_features, ...var_working_features});
        } catch (error) {
            console.error(error);
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization_features() {
        return API.get('alpha-org', '/get-organization-features/' + organization_id);
    }

    function API_put_organization_features() {
        return API.put('alpha-org', '/put-organization-features', {
            body: var_working_features
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_working_features({...var_features});
        let disabled_features = [];
        for (let property in var_features) {
            if (var_features[property] === 'YES' && property !== 'wallet' && property !== 'reports' && property !== 'manage_credentials') {
                disabled_features.push(property);
            }
        }
        set_disabled_features(disabled_features);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange(changed_feature) {
        let working_features = {...var_working_features};
        working_features[changed_feature] = working_features[changed_feature] === 'YES' ? 'NO' : 'YES';
        if (working_features[changed_feature] === 'NO') {
            // Disable sub features whose parents become disabled
            let sub_features = feature_list.find(item => item.feature === changed_feature)?.sub_features;
            sub_features?.forEach(sub_feature => {
                working_features[sub_feature.feature] = 'NO';
            });
        }
        set_working_features(working_features);
    }

    function onClick_save() {
        if (var_processing) return;
        save_record();
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='organizations_details__crd_features'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Features</div>
                    <div className='text--sm-regular'>Configure the product features for the organization.</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                    <Form className='form_features'>
                        {var_mode === 'VIEW' && 
                        <>
                            {feature_list.map((feature, index) => {
                                return (<React.Fragment key={index}>
                                    <FORM_TOGGLE
                                        label={feature.name}
                                        value={var_features[feature.feature]}
                                        options={[{value: 'YES', text: 'Yes' }, { value: 'NO', text: 'No' }]}
                                        disabled
                                        description={feature.info}
                                    />
                                    {var_features[feature.feature] === 'YES' && feature.sub_features &&
                                        <div className='detailsgroup__subgroup rounded-lg'>
                                            {feature.sub_features.map((sub_feature, sub_feature_index) => {
                                                return (
                                                    <div className='detailsgroup__subgroup__item' key={index + sub_feature_index}>
                                                        <CHECKBOX 
                                                            name={sub_feature.feature}
                                                            checked={var_features[sub_feature.feature] === 'YES'}
                                                            disabled />
                                                        <div className='detailsgroup__subgroup__item__labeltwoline'>
                                                            <div className='text--sm-medium'>{sub_feature.name}</div>
                                                            <div className='text--sm-regular'>{sub_feature.info}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    </React.Fragment>)
                            })}
                        </>}
                        {var_mode === 'EDIT' && 
                        <>
                            {feature_list.map(feature => {
                                return (<>
                                    <FORM_TOGGLE
                                        label={feature.name}
                                        value={var_working_features[feature.feature]}
                                        options={[{value: 'YES', text: 'Yes' }, { value: 'NO', text: 'No' }]}
                                        onChange={() => onChange(feature.feature)}
                                        disabled={feature.read_only || var_disabled_features.includes(feature.feature)}
                                        description={feature.info}
                                    />
                                    {var_working_features[feature.feature] === 'YES' && feature.sub_features &&
                                        <div className='detailsgroup__subgroup rounded-lg'>
                                            {feature.sub_features.map(sub_feature => {
                                                return (
                                                    <div className='detailsgroup__subgroup__item'>
                                                        <CHECKBOX 
                                                            name={sub_feature.feature}
                                                            checked={var_working_features[sub_feature.feature] === 'YES'}
                                                            onChange={() => onChange(sub_feature.feature)}
                                                            disabled={var_disabled_features.includes(sub_feature.feature)} />
                                                        <div className='detailsgroup__subgroup__item__labeltwoline'>
                                                            <div className='text--sm-medium'>{sub_feature.name}</div>
                                                            <div className='text--sm-regular'>{sub_feature.info}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    </>)
                            })}
                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>Save</Button>
                                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                            </div> 
                        </>
                        }
                    </Form>
                    <Processing display={var_processing}></Processing>
            </div>
        </div>
    );
};