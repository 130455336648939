import React, { useEffect, useState } from 'react';
import API from '../libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX } from './cmp_form/cmp_form';
import Icon from './cmp_icon';
import Processing from './cmp_processing';


export default function CMP_INDIVIDUAL_STATUS({ individual_id, invitestatus, organizationindividual_id, is_open, onClose, onOpen, onChange, onMessage }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_popup, set_popup ] = useState(null);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_invite_status_${individual_id}`);

    const individual_invitestatus_labels = {
        ACTIVE: 'Active', 
        ARCHIVE: 'Archive',
        DECLINED: 'Declined',
        EXPIRED: 'Expired', 
        INACTIVE: 'Inactive', 
        INVITED: 'Invited',
        'PENDING INVITE': 'Pending', 
    };

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_statuspopup_value(invitestatus === 'Inactive' ? 'ARCHIVE' : '');
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_indv_resendinvite(organizationindividual_id) {
        console.log(organizationindividual_id);
        return API.post('alpha-org', '/post-indv-resendinvite/', { body: { organizationindividual_id }});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_updatestatus() {
        onMessage(null, null);
        let current_value = invitestatus;
        if (current_value === var_statuspopup_value) {
            onClose();
            return;
        }

        if (!var_statuspopup_value){
            onMessage('ERROR', 'Please select a status');
            return;
        }

        set_processing(true);
        try {
            if (['INVITED', 'EXPIRED', 'DECLINED'].includes(invitestatus)) {
                if (var_statuspopup_value === 'Resend') {
                    await API_post_indv_resendinvite(organizationindividual_id);
                } else {
                    throw new Error('Invalid option');
                }
                onChange(var_focuselement?.id);
            }
        } catch(e) {
            console.log(e);
            onMessage('ERROR', 'There was a problem saving.  Please try again later');
        }
        set_processing(false);
    }

    function onClick_status(){
        set_focuselement(document.activeElement);
    }

    // RENDER APP ======================================================================================================

    return (
            <Popup
                trigger={
                    <Button
                    onClick={onClick_status}
                    id={`btn_${POPUP_ID}`}
                    className={'badge text--sm-medium  ' +
                        (   invitestatus === 'ACTIVE' ? 'badge--green'
                            : invitestatus === 'INACTIVE' ? 'badge--gray'
                            : invitestatus === 'PENDING INVITE' ? 'badge--yellow'
                            : (invitestatus === 'EXPIRED' || invitestatus === 'DECLINED') ? 'badge--red'
                            : 'badge--gray')}
                    type='button'
                    disabled={!['INVITED', 'EXPIRED', 'DECLINED'].includes(invitestatus)}
                    >
                        {individual_invitestatus_labels[invitestatus]}
                        {['INVITED', 'EXPIRED', 'DECLINED'].includes(invitestatus) && <Icon name='badge_dropdown' className='badge__icon' />}
                    </Button>
                }
                content={
                    is_open ?
                        <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} aria-label={'Update invite status'}>
                            <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={'Change individual status'}>{'Change status'}</div>
                            <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                                {invitestatus === 'INVITED' || invitestatus === 'EXPIRED' || invitestatus === 'DECLINED' ?
                                <>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={'Resend invitation'} value='Resend' checked={var_statuspopup_value === 'Resend'} onChange={() => set_statuspopup_value('Resend')} />
                                </div>
                                </>
                                : null
                                }
                                <Button type='button' className='badge__popup__button secondary' onClick={onClick_updatestatus}>{'Update'}</Button>
                            </Form>
                            <Processing display={var_processing} processingtext='' />

                        </div>
                        : null
                }
                on='click'
                open={is_open}
                onOpen={() => onOpen(individual_id)}
                onClose={onClose}
                hideOnScroll
                position='bottom left' />
    );
}